<template>
  <section class="add-banner__modal-container">
    <div class="add-banner__upload-wrapper">
      <div class="add-logo__top-container">
        <font-awesome-icon
          @click="closeModal"
          class="add-logo__close-icon"
          icon="times"
        ></font-awesome-icon>
        <div style="text-align: center">
          <p class="add-logo__title">
            Formato PNG o JPEG - Dimensiones
            {{
              bannerDimensionsForThisLayout.height +
              "x" +
              bannerDimensionsForThisLayout.width
            }}px - Tamaño máximo 5MB
          </p>
        </div>
      </div>

      <div class="add-banner__middle-container">
        <UploadImg
          v-if="img_preview_src == ''"
          @sendImg="handleBannerSelect"
          :maxSizeMB="5"
          :viewport="viewportForThisLayout"
          :dimensions="bannerDimensionsForThisLayout"
          style="margin: auto"
          :style="previewDimensions"
          ref="uploadImgRef"
        />
        <div class="img-preview" v-if="img_preview_src !== ''">
          <div class="delete-preview" @click="deletePreview()">
            <button>
              <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
          </div>
          <div class="img-preview-container">
            <img :src="img_preview_src" alt="" />
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <p class="add-banner__legend-text text-center mb-0">Vista previa</p>
        </div>
      </div>

      <div class="add-logo__buttons-container">
        <button @click="closeModal" class="add-logo__button-cancel">
          Cancelar
        </button>
        <button @click="loadBanner" class="add-logo__button-add">
          Guardar
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UploadImg from "../UploadImg.vue";

const defaultNewBanner = {
  width: "",
  height: "",
  src: "",
  type: "",
  name: "",
};

export default {
  components: { UploadImg },
  data() {
    return {
      forBannerH:
        this.$route.name === "CanalCorporativo-listadoBannersHorizontal",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      img_preview_src: "",
      newBanner: {},
    };
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),

    bannerDimensionsForThisLayout() {
      if (this.forBannerH) {
        return { height: 267, width: 1437 };
      } else if ([4, 5].includes(+this.channelLayout.id_layout)) {
        return { height: 1080, width: 483 };
      } else {
        return { height: 714, width: 480 };
      }
    },

    viewportForThisLayout() {
      if (this.forBannerH) {
        return { height: 133, width: 718 };
      } else if ([4, 5].includes(+this.channelLayout.id_layout)) {
        return { height: 360, width: 161 };
      } else {
        return { height: 357, width: 240 };
      }
    },

    previewDimensions() {
      if (this.forBannerH) {
        return ""; //{ 'height': '195px', 'width': '550px' }
      } else if ([4, 5].includes(+this.channelLayout.id_layout)) {
        return { height: "363px", width: "173px" };
      } else {
        return { height: "367px", width: "253px" };
      }
    },
  },

  methods: {
    ...mapActions("tvCorporativaModule", ["uploadFileSubscription"]),

    async loadBanner() {
      const data = {
        id_empresa: this.id_empresa,
        id_usuario: this.id_usuario,
        tipo_archivo: this.forBannerH ? 5 : 4,
        file: this.newBanner,
      };

      this.$emit("showSpinner");

      try {
        const fileObservable = await this.uploadFileSubscription(data);
        const subscription = fileObservable.subscribe((data) => {
          const uploadProgressData = data.data.saveFileRepo;
          if (uploadProgressData.percent === "100") {
            setTimeout(() => {
              subscription.unsubscribe();
              this.toastBannerCargaExitosa();
              this.$emit("bannerUploaded");
              this.$emit("hideSpinner");
              this.closeModal();
            }, 1500);
          }
        });
      } catch (error) {
        this.toastBannerFallaCarga();
        this.$emit("hideSpinner");
        this.closeModal();
      }
    },

    handleBannerSelect(img) {
      this.newBanner = {
        width: img.width,
        height: img.height,
        src: img.src,
        type: "image/" + img.format,
        name: img.name,
        duration: 0,
      };
    },

    closeModal() {
      this.forBannerH =
        this.$route.name === "CanalCorporativo-listadoBannersHorizontal";
      this.id_empresa = this.$ls.get("user").empresa[0].id_empresa;
      this.id_usuario = this.$ls.get("user").id_usuario;
      this.img_preview_src = "";
      this.newBanner = {};
      this.$refs.uploadImgRef.resetImage();
      this.$emit("closeModal");
    },

    deletePreview() {
      this.newBanner = defaultNewBanner;
    },

    toastBannerFallaCarga() {
      this.$toast.open({
        message: "Falla al momento de cargar banner.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastBannerCargaExitosa() {
      this.$toast.open({
        message: "El banner ha sido cargado exitosamente.",
        type: "success",
        duration: 6000,
        position: "top-right",
      });
    },
    toastBannerFallaFormato() {
      this.$toast.open({
        message:
          "Por favor selecciona un archivo con formato válido (.png, .jpg).",
        type: "warning",
        duration: 6000,
        position: "top-right",
      });
    },
  },
};
</script>
